import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { taskMiddleware } from "react-palm/tasks";

import keplerGlReducer from "kepler.gl/reducers";
import { LOCALE_CODES } from "kepler.gl/localization";

import UserService from "./services/UserService";

import Rastrear from "./components/rastrear";
import Map from "./components/map";

import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Map />,
    children: [],
  },
  {
    path: "/rastrear",
    element: <Rastrear />,
  },
]);

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    locale: LOCALE_CODES.pt,
    currentModal: null,
  },
});

const store = configureStore({
  reducer: {
    keplerGl: customizedKeplerGlReducer,
  },
  middleware: [taskMiddleware],
});

UserService.initKeycloak((canAccess: boolean) => {
  // if (canAccess) setVisible(true);
  // else
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        {canAccess ? (
          <RouterProvider router={router} />
        ) : (
          <div>Access Denied</div>
        )}
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
