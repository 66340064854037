import { useDispatch } from "react-redux";
import UserService from "../../services/UserService";
import {
  DataResult,
  GetBases,
  GetData,
  GetDataAreas,
  GetDataLocationHistory,
  MapDataType,
} from "../../types";
import React, { useEffect, useState } from "react";
import { addDataToMap, updateMap } from "kepler.gl/actions";
import KeplerGl from "kepler.gl";
import { ThreeCircles } from "react-loader-spinner";
import AutoSizer from "react-virtualized-auto-sizer";
import "moment";
import moment from "moment";

interface Props {
  height: number;
  width: number;
  // any props that come into the component
}

export default function Map() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, []);

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const vehicleId = urlParams.get("vehicleId");
    const start = urlParams.get("start");
    const end = urlParams.get("end");

    let headers = new Headers();
    headers.set("Authorization", `Bearer ${UserService.getToken()}`);
    const baseUrl = process.env.REACT_APP_BASEURL;
    const response = fetch(
      baseUrl + "/api/v1/veiculo/localizacoes?padrao=nomeado&cached=true",
      { headers }
    );
    const areaCobertura = fetch(baseUrl + "/api/v1/GrupoLugar/area-cobertura", {
      headers,
    });
    const bases = fetch(baseUrl + "/api/v1/Servico/buscarbasesporcidade", {
      headers,
    });

    let allPromise: Promise<[Response?, Response?, Response?, Response?]>;
    if (vehicleId && start && end) {
      const locationHistory = fetch(
        baseUrl +
          "/api/v1/Telemetria/?veiculoId=" +
          vehicleId +
          "&dataInicio=" +
          moment(new Date(start)).add(3, "hours").format("YYYY-MM-DDTHH:mm") +
          "&dataFim=" +
          moment(new Date(end)).add(3, "hours").format("YYYY-MM-DDTHH:mm") +
          "&filtroVelocidade=false&locatarioId=0&scale=0",
        { headers }
      );
      allPromise = Promise.all([
        response,
        areaCobertura,
        bases,
        locationHistory,
      ]);
    } else {
      allPromise = Promise.all([response, areaCobertura, bases]);
    }

    try {
      const values = await allPromise;
      const data: GetData = await values[0]?.json();
      const dataAreaCobertura: GetDataAreas = await values[1]?.json();
      const dataBases: GetBases = await values[2]?.json();
      const dataHistory: GetDataLocationHistory = await values[3]?.json();

      const datas = [...data.dataResult];
      const mapData = getMapData(datas);
      const areasData: MapDataType = getAreasData(dataAreaCobertura);
      const dataBasesRows = getBasesData(dataBases);

      if (vehicleId && start && end) {
        const dataHistoryRows = getHistoricalData(dataHistory);

        return {
          mapData,
          dataHistory: dataHistoryRows,
          dataBases: dataBasesRows,
          areasData,
        };
      }
      return { mapData, dataBases: dataBasesRows, areasData };
    } catch (error) {
      console.log(error);
    }

    function getHistoricalData(dataHistory: GetDataLocationHistory) {
      return {
        fields: [
          {
            name: "eventDate",
            format: "dd/MM/yyyy hh:mm:ss",
            type: "time",
          },
          {
            name: "latitude",
            format: "",
            type: "real",
          },
          {
            name: "longitude",
            format: "",
            type: "real",
          },
          {
            name: "speed",
            format: "",
            type: "string",
          },
          {
            name: "odometer",
            format: "",
            type: "string",
          },
          {
            name: "mainBatery",
            format: "",
            type: "string",
          },
          {
            name: "backupBatery",
            format: "",
            type: "string",
          },
          {
            name: "coveringViolation",
            format: "",
            type: "string",
          },
          {
            name: "shieldViolation",
            format: "",
            type: "string",
          },
        ],
        rows: [...dataHistory.dataResult].map((result: any) => [
          moment(new Date(result.eventDate))
            // .subtract(3, "hours")
            .format("YYYY/MM/DD HH:mm") || null,
          result.latitude || null,
          result.longitude || null,
          result.speed || null,
          result.odometer || null,
          result.mainBatery || null,
          result.backupBatery || null,
          result.coveringViolation.toString() || null,
          result.shieldViolation.toString() || null,
        ]),
      };
    }

    function getBasesData(dataBases: GetBases) {
      return {
        fields: [
          {
            name: "latitude",
            format: "",
            type: "real",
          },
          {
            name: "longitude",
            format: "",
            type: "real",
          },
          {
            name: "nome",
            format: "",
            type: "string",
          },
          {
            name: "icon",
            format: "",
            type: "string",
            visible: false,
          },
        ],
        rows: [...dataBases.dataResult].map((result: any) => [
          result.latitude || null,
          result.longitude || null,
          result.nome || null,
          "home",
        ]),
      };
    }

    function getAreasData(dataAreaCobertura: GetDataAreas): MapDataType {
      return {
        fields: [
          {
            name: "areaCirculacao",
            displayName: "Area de circulação",
            format: "",
            type: "geojson",
          },
          {
            name: "areaCobertura",
            displayName: "Area de cobertura",
            format: "",
            type: "geojson",
          },
        ],
        rows: dataAreaCobertura.dataResult.map((result: any) => [
          result?.areaCirculacao || null,
          result?.areaCobertura || null,
        ]),
      };
    }

    function getMapData(datas: DataResult[]) {
      return {
        fields: [
          {
            name: "placa",
            format: "",
            displayName: "Placa",
            type: "string",
          },
          {
            name: "latitude",
            displayName: "Latitude",
            format: "",
            type: "real",
          },
          {
            name: "longitude",
            displayName: "Longitude",
            format: "",
            type: "real",
          },
          {
            name: "cidade",
            displayName: "Cidade",
            format: "",
            type: "string",
          },
          {
            name: "modelo",
            format: "",
            displayName: "Modelo",
            type: "string",
          },
          {
            name: "veiculoSituacao",
            format: "",
            displayName: "Situação",
            type: "string",
          },
          {
            name: "escalaPing",
            type: "string",
            displayName: "Escala de ping",
          },
        ],
        rows: datas.map((result: any) => [
          result.placa || null,
          result.latitude || null,
          result.longitude || null,
          result?.nome || null,
          result?.modelo || null,
          result?.veiculoSituacao || null,
          result?.escalaPing || null
        ]),
      };
    }
  };

  React.useEffect(() => {
    fetchData().then((response) => {
      setLoading(false);
      if (response) {
        const basesLayer = {
          id: "1s8r5mf",
          type: "icon",

          config: {
            dataId: "bases",
            label: "Bases",
            color: [255, 176, 0],
            columns: {
              lat: "latitude",
              lng: "longitude",
              icon: "icon",
              altitude: null,
            },
            visConfig: {
              radius: 30,
              opacity: 1,
              thickness: 1,
              strokeColor: [77, 193, 156],
            },
            isVisible: true,
            textLabel: [
              {
                field: "nome",
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
        };

        const getVeiculosLayer = (
          vehicleId: string | null,
          start: string | null,
          end: string | null
        ) => {
          return {
            id: "1s8r5md",
            type: "point",
            config: {
              dataId: "mottu",
              label: "Motos",
              color: [0, 177, 49],
              visConfig: {
                opacity: 0.8,
                thickness: 1,
                strokeColor: [77, 193, 156],
                colorRange: {
                  name: "Escalas de ping",
                  type: "custom",
                  category: "custom",
                  colors: ["#00CA43", "#FF9000", "#FF0005"],
                  reversed: true,
                },
              },
              columns: {
                lat: "latitude",
                lng: "longitude",
                altitude: null,
              },
              isVisible: vehicleId && start && end ? false : true,
              textLabel: [
                {
                  field: "placa",
                  color: [255, 255, 255],
                  size: 18,
                  offset: [0, 0],
                  anchor: "start",
                  alignment: "center",
                },
              ],
            },
            visualChannels: {
              colorField: {
                name: "escalaPing",
                type: "string",
              },
              colorScale: "quantize",
            },
          };
        };

        const limiteLayer = {
          id: "1s8r5m2",
          type: "geojson",
          config: {
            dataId: "areas",
            label: "Limite máximo",
            color: null,
            columns: {
              geojson: "areaCirculacao",
            },
            isVisible: false,
            visConfig: {
              opacity: 0.8,
              thickness: 1,
              strokeColor: [77, 193, 156],
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 22.5,
              sizeRange: [0, 10],
              radiusRange: [0, 50],
              heightRange: [0, 500],
              elevationScale: 5,
              stroked: true,
              filled: false,
              enable3d: false,
              wireframe: false,
            },
          },
          visualChannels: {
            colorField: {
              name: "cidade",
              type: "string",
            },
            colorScale: "ordinal",
          },
        };

        const coberturaLayer = {
          id: "1s8r5m3",
          type: "geojson",
          config: {
            dataId: "areas",
            label: "Area circulação",
            color: null,
            columns: {
              geojson: "areaCobertura",
            },
            isVisible: false,
            visConfig: {
              opacity: 0.8,
              thickness: 1,
              strokeColor: [255, 254, 213],
              colorRange: {
                name: "UberPool 8",
                type: "diverging",
                category: "Uber",
                colors: [
                  "#213E9A",
                  "#3C1FA7",
                  "#811CB5",
                  "#C318B0",
                  "#D01367",
                  "#DE0F0E",
                  "#EC7007",
                  "#F9E200",
                ],
                reversed: false,
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 10,
              sizeRange: [0, 10],
              radiusRange: [0, 50],
              heightRange: [0, 500],
              elevationScale: 5,
              stroked: true,
              filled: false,
              enable3d: false,
              wireframe: false,
            },
          },
          visualChannels: {
            colorField: {
              name: "cidade",
              type: "string",
            },
            colorScale: "ordinal",
          },
        };

        const urlParams = new URLSearchParams(window.location.search);
        const vehicleId = urlParams.get("vehicleId");
        const start = urlParams.get("start");
        const end = urlParams.get("end");

        const datasets = [
          {
            info: {
              label: "Mottu",
              id: "mottu",
            },
            data: response.mapData,
          },
          {
            info: {
              label: "Bases",
              id: "bases",
            },
            data: response.dataBases,
          },
          {
            info: {
              label: "Areas",
              id: "areas",
            },
            data: response.areasData,
          },
        ];

        const layers = [
          basesLayer,
          getVeiculosLayer(vehicleId, start, end),
          limiteLayer,
          coberturaLayer,
        ];

        let mapConfig = {
          datasets: datasets,
          option: {
            centerMap: vehicleId && start && end ? false : true,
            readOnly: false,
          },
          config: {
            visState: {
              filters: [
                {
                  dataId: "mottu",
                  id: "modelo",
                  name: "modelo",
                  label: "Mottu",
                  type: "MultiSelect",
                  value: ["Pop", "Sport", "Sport150", "MottuE", "SportMx"],
                  enlarged: false,
                  plotType: "histogram",
                  animationWindow: "free",
                  yAxis: null,
                  speed: 1,
                },
              ],
              layers: layers,
              layerBlending: "normal",
              splitMaps: [],
            },
            mapStyle: {
              styleType: "dark",
            },
          },
        };

        if (vehicleId && response.dataHistory) {
          mapConfig.datasets.push({
            info: {
              label: "History",
              id: "history",
            },
            data: response.dataHistory,
          });

          const historicoLayer = {
            id: "1s8r5m5",
            type: "point",
            config: {
              dataId: "history",
              label: "History",
              color: null,
              columns: {
                lat: "latitude",
                lng: "longitude",
              },
              visConfig: {
                opacity: 0.8,
                thickness: 0.5,
                strokeColor: [255, 0, 0],
              },
              isVisible: true,
              textLabel: [],
            },
            visualChannels: {},
          };

          // @ts-ignore
          layers.push(historicoLayer);
          // @ts-ignore
          // mapConfig.config.visState.layers.push(historicoLayer);
        }

        dispatch(addDataToMap(mapConfig));

        if (vehicleId && response.dataHistory) {
          dispatch(
            updateMap({
              latitude:
                response.dataHistory.rows[
                  response.dataHistory.rows.length - 1
                ][1],
              longitude:
                response.dataHistory.rows[
                  response.dataHistory.rows.length - 1
                ][2],
              zoom: 12,
              width: 800,
              height: 1200,
            })
          );
        }
      }
    });
  }, [dispatch]);

  return !loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <AutoSizer>
        {({ height, width }: Props) => (
          <KeplerGl
            id="maps"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
            width={width}
            height={height}
          />
        )}
      </AutoSizer>
    </div>
  ) : (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "#242730",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThreeCircles
        height="100"
        width="100"
        color="#00B131"
        visible={true}
        ariaLabel="three-circles-rotating"
      />
    </div>
  );
}
