import * as React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

ControlPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
function ControlPanel({ onChange, value }) {
  return (
    <div className="control-panel">
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          defaultValue={0.01}
          name="controlled-radio-buttons-group"
          onChange={onChange}
          value={value}
        >
            <FormControlLabel
            value={0.01}
            control={<Radio size="small" />}
            label="10 metros"
          />
          <FormControlLabel
            value={0.1}
            control={<Radio size="small" />}
            label="100 metros"
          />
          <FormControlLabel
            value={0.2}
            control={<Radio size="small" />}
            label="200 metros"
          />
          <FormControlLabel
            value={0.5}
            control={<Radio size="small" />}
            label="500 metros"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default React.memo(ControlPanel);
